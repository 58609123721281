import './App.css';

import React from 'react';
import ReactHlsPlayer from 'react-hls-player';



function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const playlist = urlParams.get("playlist")

  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

  const src = baseUrl + playlist + '/stream.m3u8'

  setTimeout(() => {
    window.location.reload()
  }, 1000 * 60 * 60 * 4)

  const playerRef = React.useRef();

  React.useEffect(() => {
    console.log(playerRef.current)
    function fireOnVideoError() {
      window.location.reload()
    }

    let wait_to_much_flag = false
    let not_loading_flag = false

    let timeoutJob = null
    function fireOnVideoWaiting() {
      console.log("waiting")
      if (!wait_to_much_flag) {
        if (timeoutJob) {
          clearTimeout(timeoutJob)
        }
      } else { return }

      wait_to_much_flag = true


      timeoutJob = setTimeout(() => {
        if (wait_to_much_flag) { window.location.reload() }
      }, 20000)
    }

    function fireOnVideoLoad() {
      console.log("loaded")
      wait_to_much_flag = false
      not_loading_flag = false
    }

    setInterval(() => {
      if (not_loading_flag) {
        window.location.reload()
      }
      not_loading_flag = true
    }, 60 * 10000 * 3)

    let one_time = false

    function fireOnVideoStart() {
      console.log("start")
      if (!one_time) {
        playerRef.current.currentTime = playerRef.current.duration / 2
        if (playerRef.current.requestFullscreen) {
          playerRef.current.requestFullscreen().catch((e) => { console.error(e) })
        }
      }
      one_time = true
    }


    playerRef.current.addEventListener('error', fireOnVideoError);
    playerRef.current.addEventListener('canplaythrough', fireOnVideoLoad);
    playerRef.current.addEventListener('waiting', fireOnVideoWaiting);
    playerRef.current.addEventListener('canplaythrough', fireOnVideoStart);

    setInterval(() => {
      console.log("force play")
      playerRef.current.play()
      playerRef.current.muted = false
    }, 20000)

  }, []);


  return (
    <div className="App">
      <ReactHlsPlayer
        src={src}
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
        playerRef={playerRef}
        debug="true"
      />
    </div>
  );
}

export default App;
